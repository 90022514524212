@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip{
    visibility: hidden;
    position: fixed;
  }
.has-tooltip:hover .tooltip {
    visibility: visible;
    z-index: 100;
}

.ant-timeline-item{
  padding-bottom:0px !important;
}

