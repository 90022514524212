@import '~antd/dist/antd.css';
.App {
  text-align: center;
}

.ant-select-selector{
 min-height: 38px !important;
 border-radius: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media print {}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {

  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  height: 100%;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}

.ant-btn .ant-btn-default{
  display: inline-flex !important;
}

.ant-pagination-item-active {
  background-color: #0f2956;
  border-radius: 5px !important;
  border-color: #0f2956;
}
.ant-pagination-item-active a {
  color: white;  
}

.ant-pagination-item-active:hover {
  border-color: #0f2956;
}

.ant-select-selection-placeholder {
  color: rgb(107, 114, 128);
}

button.outline-none {
  outline: none !important;
}